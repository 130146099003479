.shopProductButton {
	padding: 5px 7px;
	border: none;
	border-radius: 10px;
    height: 50%;
}

.shopProductButton.In {
	background-color: #e5faf2;
	color: #3bb077;
    font-size: 18px;
}

.shopProductButton.Out {
	background-color: #fff0f1;
	color: #d95087;
    font-size: 18px;
}

.shopProductButton.Pending {
	background-color: #ebf1fe;
	color: #3bb077;
    font-size: 18px;
}

/* .shopProductButton.In {
	background-color: #e5faf2;
	color: #3bb077;
    font-size: 18px;
}

.shopProductButton.Out {
    background-color: #fff0f1;
	color: #d95087;
    font-size: 18px;
}

.shopProductButton.Pending {
    background-color: #ebf1fe;
	color: #3bb077;
    font-size: 18px;
} */

.shopProductButton.Purchase {
	background-color: #e5faf2;
	color: #3bb077;
    cursor: pointer;
    font-size: 18px;
}